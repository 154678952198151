export const { default: IconBack } = require('./IconBack');
export const { default: IconCards } = require('./IconCards');
export const { default: IconChat } = require('./IconChat');
export const { default: IconCopy } = require('./IconCopy');
export const { default: IconEdit } = require('./IconEdit');
export const { default: IconEye } = require('./IconEye');
export const { default: IconFacebook } = require('./IconFacebook');
export const { default: IconFilters } = require('./IconFilters');
export const { default: IconGithub } = require('./IconGithub');
export const { default: IconHeart } = require('./IconHeart');
export const { default: IconPointer } = require('./IconPointer');
export const { default: IconQuestion } = require('./IconQuestion');
export const { default: IconTiktok } = require('./IconTiktok');
export const { default: IconTrash } = require('./IconTrash');
export const { default: IconUsers } = require('./IconUsers');
export const { default: IconWebsite } = require('./IconWebsite');
export const { default: IconYoutube } = require('./IconYoutube');
